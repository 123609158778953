import React from "react"
import SEO from "../components/seo"
import Layout from "../containers/layout/layout"
import Header from "../containers/layout/header"
import Footer from "../containers/layout/footer"
import HeroArea from "../containers/page-areas/hero-area"
import BlogArea from "../containers/page-areas/blog-area"
import InstagramArea from "../containers/widgets/feature-posts"
import { graphql, useStaticQuery } from "gatsby"

const IndexPage = () => {
  const heroData = useStaticQuery(graphql`
    query BlogHeroQuery {
      blogJson(id: { eq: "blog-metadata" }) {
        heroImage {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 750, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  const { heroImage } = heroData.blogJson
  return (
    <Layout>
      <SEO title="Blog | Envy Forge" />
      <Header />
      <div className="main-content">
        <HeroArea image={heroImage} featuredposts={true} />
        <BlogArea />
        <InstagramArea />
      </div>
      <Footer />
    </Layout>
  )
}

export default IndexPage
